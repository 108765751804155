<template>
  <v-container class="py-0 stepper-component">
    <v-card flat class="rounded-1" width="100%">
      <v-stepper 
        :value="stepperValue" 
        :alt-labels="$vuetify.breakpoint.xsOnly" 
        dark
        class="py-0 py-md-0 primary"
      >
        <v-stepper-header 
          class="primary"
        >
          <slot></slot>
        </v-stepper-header>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'page-stepper',
  props: {
    stepperValue: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      e6: 1,
    }
  },
}
</script>

<style>

</style>