<template>
  <v-app>
    <TheNavbar v-if="authState === 'signedin' && user" />
    
    <!-- This nav bar appears when user navigates to the agreement pages -->
    <!-- <AgreementNavBar v-if="$route.path.startsWith('/review')" /> -->

    <v-main class="grey lighten-4 pb-12">
      <div
        v-if="authState !== 'signedin'"
        class="background-image"
      >
      </div>
      <div 
        v-if="authState !== 'signedin'"
        class="login-logo cursor-pointer" 
        @click="toAgentOS"
      >
        <v-img
          alt="AgentOS logo"
          contain
          src="../src/assets/AgentOS-Logo.png"
        />
      </div>
      <div 
        v-if="authState !== 'signedin'"
        class="authenticator"  
      >
        <amplify-auth-container>
          <amplify-authenticator 
            username-alias="email"
          >
            <amplify-sign-up
              header-text="Create an account on AgentSIGN"
              slot="sign-up"
              username-alias="email"
              :form-fields.prop="formFields"
            ></amplify-sign-up>
            <amplify-sign-in
              :form-fields.prop="signInFormFields"
              header-text="AgentSIGN Login"
              slot="sign-in" 
              username-alias="email"
            ></amplify-sign-in>
            <amplify-confirm-sign-up
              header-text="Enter your verification code below"
              slot="confirm-sign-up"
            ></amplify-confirm-sign-up>
          </amplify-authenticator>
        </amplify-auth-container>
      </div>
      <div v-if="authState === 'signedin' && user"> 
        <router-view></router-view>   
      </div>

      <!-- A floating button with chat component -->
      <!-- <MyChat 
        v-if="landlordUser && $route.path !== '/' && $route.path !== '/secondarylogin' && !$route.path.startsWith('/review')"  
      /> -->
    </v-main>

    <!-- Only visible on tenantApp -->
    <!-- <v-bottom-navigation 
      class="d-md-none" 
      v-if="tenantUser"
      shift
      fixed
      dark
    >
      <v-btn to="/homepage" value="home">
        <span>Home</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn to="tenant-journey" value="journey">
        <span>Journey</span>
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn to="account" value="account">
        <span>Account</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation> -->

  </v-app>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

import TheNavbar from '@/components/navBars/TheNavbar.vue';
// import AgreementNavBar from '@/components/navBars/AgreementNavBar.vue';

export default {
  name: 'App',
  components: {
    TheNavbar,
    // AgreementNavBar, //For agreement pages
  },
  created() {
    console.log('URL params from app.vue: ', this.$route.query.agency)
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if(authState !== 'signin') {
        if(this.user) {
          let userDetails = this.user.attributes
          this.$store.commit('SET_USER_DETAILS', userDetails)
        }
      }
      else {
        this.$store.commit('LOGOUT')
        if(this.$route.path !== '/') {
          this.$router.push('/')
        }
      }
    })

    if(localStorage.personAccounts) {
      // console.log('Person accounts present in localStorage')
      this.$store.commit('SET_ALL_PERSON_ACCOUNTS', JSON.parse(localStorage.personAccounts))
    }
    if(localStorage.selectedPersonAccount) {
      // console.log('Selected person account present in localStorage')
      this.$store.commit('SET_SELECTED_PERSON_ACCOUNT', JSON.parse(localStorage.selectedPersonAccount))
    }
    if(localStorage.selectedProperty) {
      // console.log('Selected property present in localStorage')
      this.$store.commit('SET_SELECTED_PROPERTY', JSON.parse(localStorage.selectedProperty))
    }
  },
  mounted() {
    console.log('*** AgentSIGN design by Claudio Rescigno (AgentOS) ***')
  },
  data: () => ({
    user: null,
    authState: null,
    drawer: false,
    formFields: [
      {
        type: 'email',
        // label: 'Email address *',
        // placeholder: 'Enter your email address',
        // required: true,
      },
      {
        type: 'password',
        // label: 'Password *',
        // placeholder: 'Enter a password of your choice',
        // required: true,
      },
    ],
    signInFormFields: [
      {
        type: 'email',
        label: 'Email address *',
        placeholder: 'Enter your email address',
        // inputProps: {autocomplete: 'username', name: 'email'}
        // required: true,
      },
      {
        type: 'password',
        label: 'Password *',
        placeholder: 'Enter your password',
        // inputProps: {autocomplete: 'current-password', name: 'password'}
        // required: true,
      },
    ],
  }),
  methods: {
    toAgentOS() {
      let url = "https://agentos.com/"
      window.open(url, "_blank")
    }
  },
  beforeDestroy() {
    return onAuthUIStateChange
  }
}
</script>

<style>
/* Amplify authenticator components ---------------------------------------------- */
/* amplify-sign-in {
  color: green;
  padding: 15px;
} */

.v-select,
.v-input {
  border-radius: 0.125rem;
}

.vue-friendly-iframe iframe {
    width : 100%;
    height: 300px;
}

.background-image {
  background-image: url("../src/assets/agreement-summary.jpg");
  background-position: center left;
  background-size: cover;

  height: 110%;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.5;
}

.login-logo {
  /* background: white; */
  width: 100%;
  height: 80px;
  display: flex;
  position: absolute;
  bottom: -30px;
  left: 0;
  z-index: 10;
}

.authenticator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* App Vue.js transitions -------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */
.group-slide-left-enter-active {
  transition: all calc(var(--i) * .3s) cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: .3s;
}

.group-slide-left-enter {
  opacity: 0;
  transform: translateX(25px) translateY(25px);
}

.cursor-pointer {
  cursor: pointer;
}

/* Background images for all pages displaying this class on the v-img */
.bg-img {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.3;
  filter: blur(2px);
}

/* Stepper component on some page headers ------------------------------------------ */
/* --------------------------------------------------------------------------------- */
.v-stepper__header {
  box-shadow: none !important;
}
.v-stepper {
  box-shadow: none !important;
}
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 70px !important;
}

/* Review agreement pages (contract pages) ----------------------------- */
.section-title {
  position: fixed;
  width: calc(100% - 16.6666%); 
}

.tab-item-card {
  border-radius: 0 !important;
}

#summary-img {
  position: absolute;
  filter: opacity(0.2) grayscale(100%);
}

.section-title-bg-img{
  position: absolute;
  top: 0;
  height: 190px;
  filter: opacity(0.2);
}

/* TenantApp header ----------------------------------------------- */
.tenant-page-header {
  background-color: #262637;
  /* height: 120px; */
  height: 60px;
  width: auto-fill;
}

/* LandlordApp components --------------------------------- */
.page-header {
  height: 60px;
  width: auto-fill;
}

/* Header SVG images -------------------------------------------- */
#home-page-svg {
  position: absolute;
  right: 10px;
  bottom: -5px;
  width: 8%;
  height: auto;
}

/* Img on Viewing page is stlightly taller and needs different settings */
#viewing-svg {
  position: absolute;
  right: 10px;
  bottom: 0px;
  width: 10%;
  height: auto;
}

#offer-application-svg {
  position: absolute;
  right: 10px;
  bottom: 0px;
  width: 10%;
  height: auto;
}

#referencing-svg {
  position: absolute;
  right: 10px;
  bottom: 0px;
  width: 10%;
  height: auto;
}

#signing-svg {
  position: absolute;
  right: 15px;
  bottom: 3px;
  width: 5%;
  height: auto;
}

#moving-in-svg {
  position: absolute;
  right: 10px;
  bottom: 0px;
  width: 7%;
  height: auto;
}

#paying-rent-img {
  position: absolute;
  right: 10px;
  bottom: -0px;
  width: 9%;
  height: auto;
}

#renewal-img {
  position: absolute;
  right: 10px;
  bottom: -0px;
  width: 8%;
  height: auto;
}

/* Paying rent cards ----------------------------------------------------------------- */
.next-payment {
  border-left: 5px solid #00ded6;
  padding-left: 10px;
}

/* Landlord login ----------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------- */
.landlord-component-wrapper {
  margin-top: -120px;
}

/* @Media ----------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------- */

@media only screen and (min-width: 320px) {
  .v-stepper .v-stepper__label {
    display: -webkit-box !important; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box !important; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox !important; /* TWEENER - IE 10 */
    display: -webkit-flex !important; /* NEW - Chrome */
    display: flex !important; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  }
}

@media only screen and (min-width: 768px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 175px !important;
  }
  .tenant-page-header,
  .page-header {
    height: 75px;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  .page-header {
    margin-top: 120px;
  }
}

@media only screen and (min-width: 1024px) {
  /* .container {
    padding-top: 100px !important;
  } */
}

@media only screen and (min-width: 1025px) {
  .page-header {
    width: calc(100% - 264px);
    width: calc(100% - 50px);
  }
}

</style>
