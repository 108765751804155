<template>
  <div>
    <!-- Floaring button -->
    <v-btn 
      @click="toggleChat" 
      color="pink" 
      class="floating-chat-btn" 
      fab
      small
    >
      <v-icon small color="white">mdi-chat-outline</v-icon>
    </v-btn>

    <!-- Chat card -->
    <v-card v-if="chat" elevation="12" class="d-none d-sm-inline-block rounded-lg chat-card py-0" width="380" min-height="600" max-height="600">
      <v-card-title class="primary py-2">
        <!-- <v-avatar  size="25" color="white"> -->
          <v-icon color="white">mdi-information-outline</v-icon>
        <!-- </v-avatar> -->
        <span class="white--text text-body-1 ml-2">Support</span>
        <v-spacer></v-spacer>
        <v-btn v-if="!suggestions" small color="white" fab text @click="backToSuggestions">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>        
        <v-btn small color="white" fab text @click="closeChat">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-3">
        <div class="msg-wrapper">

          <div v-if="suggestions">
            <p class="font-weight-bold text-body-1">Top suggestions</p>
            <ol class="suggestions-list">
              <li @click="reportProblem" class="my-1 text-decoration-underline red--text text--darken-4">Report a problem</li>
              <li @click="requestMaintenance" class="my-1 text-decoration-underline red--text text--darken-4">Request maintenance</li>
              <li @click="chatToLanlord" class="my-1 text-decoration-underline red--text text--darken-4">Chat to landlord</li>
            </ol>
          </div>

          <div v-if="maintenance">
            <div>
              <p class="font-weight-bold text-h6">Request maintenance</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p class="font-weight-bold text-body-1">Request maintenance</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <v-btn color="primary">Action</v-btn>
            </div>
          </div>

          <div v-if="problem">
            <div>
              <p class="font-weight-bold text-h6">Report a problem</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p class="font-weight-bold text-body-1">Request maintenance</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <v-btn color="primary">Action</v-btn>
            </div>
          </div>

          <!-- Message bubbles -->
          <div v-if="chatBubbles">
            <div  v-for="(msg, index) in messages" :key="msg.id" class="d-flex flex-column">
              <div v-if="index === 0" class="rounded-lg pa-1 mx-auto green lighten-4 text-caption">{{ todaysDate }}</div>
              <div v-if="msg.myMessage && msg.type === 'message'" class="my-speech-bubble ml-auto">{{ msg.text }}</div>
              <div v-if="msg.myMessage && msg.type === 'file'" class="my-speech-bubble ml-auto pa-3">
                <v-icon x-large left color="white">mdi-file-document-outline</v-icon>
                <span class="font-weight-bold">File upload:</span>
                <span class="ml-2">{{msg.text}}</span>
              </div>
              <div v-if="!msg.myMessage" class="reply-speech-bubble">{{ msg.text }}</div>
            </div>
          </div>

        </div>
      </v-card-text>
      <div v-if="chatBubbles" class="actions-wrapper">
        <v-row no-gutters align="center" class="py-3">
          <v-col cols="10">
            <v-textarea
              v-model="myNewText"
              placeholder="Type a message"
              dense
              class="mb-n6 rounded-lg"
              background-color="white"
              color="blue"
              auto-grow
              outlined
              rows="1"
              row-height="15"
              append-icon="mdi-attachment"
              @keydown.enter.prevent="onKeyDown"
              @click:append="uploadFile"
            ></v-textarea>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            >
          </v-col>
          <v-col cols="2">
            <v-btn :disabled="myNewText === ''" @click="sendMessage('message')" class="ml-2" small depressed fab color="blue">
              <v-icon color="white">mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog
      ref="dialog"
      v-if="$vuetify.breakpoint.xsOnly"
      class="d-sm-none"
      v-model="chat"
      hide-overlay
      persistent
      transition="dialog-transition"
      fullscreen scrollable
    >
      <v-card>
        <v-card-title class="blue darken-4 py-2">
          <v-avatar  size="25" color="white">
            <v-icon color="blue darken-4">mdi-account</v-icon>
          </v-avatar>
          <span class="white--text text-body-1 ml-2">Rhy Jones</span>
          <v-spacer></v-spacer>
          <v-btn v-if="!suggestions" small color="white" fab text @click="backToSuggestions">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>        
          <v-btn small color="white" fab text @click="closeChat">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2 px-2 pb-16">

          <div v-if="suggestions">
            <p class="font-weight-bold text-body-1">Top suggestions</p>
            <ol class="suggestions-list">
              <li @click="reportProblem" class="my-1 text-decoration-underline red--text text--darken-4">Report a problem</li>
              <li @click="requestMaintenance" class="my-1 text-decoration-underline red--text text--darken-4">Request maintenance</li>
              <li @click="chatToLanlord" class="my-1 text-decoration-underline red--text text--darken-4">Chat to landlord</li>
            </ol>
          </div>

          <div v-if="maintenance">
            <div>
              <p class="font-weight-bold text-h6">Request maintenance</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p class="font-weight-bold text-body-1">Request maintenance</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <v-btn color="primary">Action</v-btn>
            </div>
          </div>

          <div v-if="problem">
            <div>
              <p class="font-weight-bold text-h6">Report a problem</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <p class="font-weight-bold text-body-1">Request maintenance</p>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptas quam eos omnis in tempore et, totam est, corporis quas minus impedit praesentium. Fuga quidem veniam odio sam itaque eaque beatae quos?</p>
              <v-btn color="primary">Action</v-btn>
            </div>
          </div>

          <div class="msg-wrapper" v-if="chatBubbles" ref="dialogMsgWrapper">
            <div  v-for="(msg, index) in messages" :key="msg.id" class="d-flex flex-column">
              <div v-if="index === 0" class="rounded-lg pa-1 mx-auto green lighten-4 text-caption">{{ todaysDate }}</div>
              <div v-if="msg.myMessage && msg.type === 'message'" class="my-speech-bubble ml-auto">{{ msg.text }}</div>
              <div v-if="msg.myMessage && msg.type === 'file'" class="my-speech-bubble ml-auto pa-3">
                <v-icon x-large left color="white">mdi-file-document-outline</v-icon>
                <span class="font-weight-bold">File upload:</span>
                <span class="ml-2">{{msg.text}}</span>
              </div>
              <div v-if="!msg.myMessage" class="reply-speech-bubble">{{ msg.text }}</div>
            </div>
          </div>

        </v-card-text>

        <!-- Chat action -->
        <div v-if="chatBubbles" class="actions-wrapper">
          <v-row no-gutters align="center" class="py-3">
            <v-col cols="10">
              <v-textarea
                v-model="myNewText"
                placeholder="Type a message"
                dense
                class="mb-n6 rounded-lg"
                background-color="white"
                color="blue"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                append-icon="mdi-attachment"
                @keydown.enter.prevent="onKeyDown"
                @click:append="uploadFile"
              ></v-textarea>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="image/*"
                @change="onFileChanged"
              >
            </v-col>
            <v-col cols="2">
              <v-btn :disabled="myNewText === ''" @click="sendMessage('message')" class="ml-2" small depressed fab color="blue">
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
          
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'my-chat',
  data() {
    return {
      chat: false,
      suggestions: true,
      chatBubbles: false,
      problem: false,
      maintenance: false,
      myNewText: '',
      messages: [
        { id: '1', myMessage: true, type: 'message', text: 'Hi there'},
        { id: '2', myMessage: true, type: 'message', text: 'I need some help with the fridge'},
        { id: '3', myMessage: false, type: 'message', text: 'Sure, what`s the propblem?'},
        { id: '4', myMessage: true, type: 'message', text: 'It`s not cold at all. Think it might be broken.'},
        { id: '5', myMessage: false, type: 'message', text: 'Ok, I`ll be there this afternoon to have a look.'},
      ],
      selectedFile: null,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    toggleChat() {
      this.chat = !this.chat
    },
    sendMessage(messageType = 'message') {
      let ID =  '_' + Math.random().toString(36).substr(2, 9);
      const newMessage = {
        id: ID,
        myMessage: true,
        type: messageType,
        text: this.myNewText
      } 
      this.messages.push(newMessage)
      this.myNewText = ''

      this.scrollToBottom()
      // Scrolls to bottom of msgs
      // if(this.$vuetify.breakpoint.xsOnly) {
      //   const wrapper =  this.$refs.dialogMsgWrapper
      //   wrapper.scrollTop = wrapper.scrollHeight;  
      // } else {
      //     const wrapper = this.$el.querySelector(".msg-wrapper");
      //     wrapper.scrollTop = wrapper.scrollHeight; 
      //   }

    },
    onKeyDown(e) {
      if(e.key === 'Enter') {
        this.sendMessage()
      }
    },
    uploadFile() {
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.myNewText = this.selectedFile.name
      this.sendMessage('file')
      // do something
    },
    // Suggestions -----------------------------------
    chatToLanlord() {
      this.suggestions = false
      this.chatBubbles = true
      
      // const wrapper = this.$el.querySelector(".msg-wrapper");
      // wrapper.scrollTop = wrapper.scrollHeight; 
    },
    requestMaintenance() {
      this.suggestions = false
      this.maintenance = true
    },
    reportProblem() {
      this.suggestions = false
      this.problem = true
    },
    backToSuggestions() {
      this.chatBubbles = false
      this.maintenance = false
      this.problem = false

      this.suggestions = true
    },
    closeChat() {
      this.chat = false
    },
    scrollToBottom() {
      // Scrolls to bottom of msgs
      if(this.$vuetify.breakpoint.xsOnly) {
        const wrapper =  this.$refs.dialogMsgWrapper
        wrapper.scrollTop = wrapper.scrollHeight;  
      } else {
          const wrapper = this.$el.querySelector(".msg-wrapper");
          wrapper.scrollTop = wrapper.scrollHeight; 
        }
    }
  },
  computed: {
    todaysDate() {
      if (!this.date) return null

      const [year, month, day] = this.date.split('-')
      return `${day}/${month}/${year}`
    }
  },
  watch: {
    chat(val) {
      if(val) {
        setTimeout(() => {
          this.scrollToBottom()
        }, 10)
      }
    },
    chatBubbles(val) {
      if(val) {
        setTimeout(() => {
          this.scrollToBottom()
        }, 10)
      }
    },
  }
}
</script>



<style scoped>
.floating-chat-btn {
  position: fixed;
  right: 20px;
  bottom: 80px;
}

.chat-card {
  position: fixed !important;
  bottom: 115px !important;
  right: 20px !important;
  z-index: 10;
}

.actions-wrapper {
  position: absolute;
  bottom: 0;
  background: rgb(174, 174, 174);
  width: 100%;
  padding: 0 10px;
}

.msg-wrapper {
  /* background: green; */
  padding: 12px 10px 100px;  /* Spacing for msgs to show when auto scoll to bottom */
  overflow: auto !important;
  height: calc(100% - 10px) !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.msg-wrapper::-webkit-scrollbar {
  display: none;
}

.reply-speech-bubble {
	position: relative;
	background: #eee;
  color: black;
	border-radius: .4em;
  padding: 8px;
  margin: 10px 0;
  max-width: 80%;
}

.reply-speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right-color: #eee;
	border-left: 0;
	border-top: 0;
	margin-top: -10px;
	margin-left: -7px;
}

.my-speech-bubble {
	position: relative;
	background: #2196F3;
  color: white;
	border-radius: .4em;
  padding: 8px;
  margin: 10px 0;
  max-width: 80%;
}

.my-speech-bubble:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-left-color: #2196F3;
	border-right: 0;
	border-top: 0;
	margin-top: -10px;
	margin-right: -7px;
}

.suggestions-list > li {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .msg-wrapper {
    padding: 12px 12px 100px;  /* Spacing for msgs to show when auto scoll to bottom */
    max-height: 450px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .floating-chat-btn {
    bottom: 100px;
  }
}


</style>