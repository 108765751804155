<template>
  <div>
    <v-speed-dial
      v-model="fab"
      bottom right fixed
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          @click="openDialog"
          color="black"
          dark
          fab
        >
          <v-icon color="primary" v-if="fab">
            mdi-close
          </v-icon>
          <v-icon color="primary" v-else>
            mdi-home-group
          </v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card :loading="selectingProperty">
        <v-card-title class="black">
          <span class="primary--text">My properties</span>
        </v-card-title>
        <v-card-text class="py-6">
          <p>Select a different property:</p>
          <v-select
            outlined
            color="secondary"
            :items="$store.state.myProperties"
            item-text="address"
            return-object
            v-model="$store.state.selectedProperty"
            @change="selectProperty"
            label="Property"
          ></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      selectingProperty: false,
      direction: 'top',
      fab: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
    }),
    methods: {
      openDialog() {
        this.dialog = true
      },
      selectProperty(x) {
        this.selectingProperty = true

        setTimeout(() => {
          this.selectingProperty = false
          this.dialog = false
          this.$store.commit('SET_PROPERTY', x)
        }, 2000)
    }
    },
  }
</script>