<template>
  <div>
    <v-app-bar
      app
      color="navbarColor"
      flat
      height="50"
      hide-on-scroll
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="grey"></v-app-bar-nav-icon>
      <div>
        <v-img
          alt="Application logo"
          class="cursor-pointer"
          contain
          src="../../assets/AgentOS-Logo.png"
          width="120"
          @click="$router.push('/')"
        />
      </div>
      <v-spacer></v-spacer>

      
      <!-- Navigational tabs on md screen and Up -->
      <!-- not on tenantApp -->
      <!-- <div v-if="$route.path.indexOf('tenant') > -1" class="d-none d-md-flex ml-n16">
        <v-tabs
          center-active
          centered
          dark
          color="primary"
        >
          <v-tab class="white" v-for="(tab, index) in tenantNavigationTabs" :key="index" :to="tab.route">
            <span class="text-body-2 primary--text">{{ tab.name }}</span>
          </v-tab>
        </v-tabs>
      </div> -->

      <!-- <div v-if="landlordUser" class="d-none d-md-flex ml-n10">
        <v-tabs
          background-color="navbarColor"
          center-active
          dark
        >
          <v-tab v-for="(tab, index) in landlordNavigationTabs" :key="index" :to="tab.route">
            <span class="text-body-2">{{ tab.name }}</span>
          </v-tab>
        </v-tabs>
      </div>

      <div v-if="buyerRentJourney" class="d-none d-md-flex ml-n10">
        <v-tabs
          background-color="navbarColor"
          center-active
          dark
        >
          <v-tab v-for="(tab, index) in buyerRentNavigationTabs" :key="index" :to="tab.route">
            <span class="text-body-2">{{ tab.name }}</span>
          </v-tab>
        </v-tabs>
      </div>

      <div v-if="vendorUser" class="d-none d-md-flex ml-n10">
        <v-tabs
          background-color="navbarColor"
          center-active
          dark
        >
          <v-tab v-for="(tab, index) in vendorNavigationTabs" :key="index" :to="tab.route">
            <span class="text-body-2">{{ tab.name }}</span>
          </v-tab>
        </v-tabs>
      </div> -->

      <!-- <amplify-sign-out></amplify-sign-out> -->

      <!-- Main account menu -->
      <v-menu min-width="200" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn 
            v-on="on" 
            class="md-0 ml-5" 
            small
            fab
            text
          >
            <v-icon color="grey">mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list class="pt-0 pb-3">
          <v-list-item class="primary">
            <v-list-item-title>
              <span class="white--text">{{ $store.state.userDetails.email }}</span>
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item v-if="buyerUser" to="/contacts">
            <v-list-item-title>
              <span class="grey--text text--darken-1">My contacts</span>
            </v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item to="/account">
            <v-list-item-title>
              <span class="grey--text text--darken-1">Account details</span>
            </v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item to="/">
            <v-list-item-title>
              <span class="grey--text text--darken-1">Switch account</span>
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item v-if="landlordUser" to="/create-tenancy">
            <v-list-item-title>
              <span class="grey--text text--darken-1">Tenancy overivew</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mt-3 d-flex justify-center">
            <amplify-sign-out></amplify-sign-out>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      clipped
      width="264"
      v-model="drawer"
      app
      mobile-breakpoint="4000"
    >
      <!-- <TheNavDrawerContent /> -->
      <!-- <LandlordNavBarContent v-if="landlordUser" /> -->

      <!-- Person ACCOUNTS list -->
      <div v-if="$store.state.personAccounts.length" class="d-flex flex-column">
        <!-- <span class="mt-3 ml-2 text-body-1 font-weight-medium">My accounts</span> -->
        <div class="px-3 pt-3 pb-0">
          <v-select
            class="mb-n3"
            dense
            outlined
            label="Agency"
            :items="agencyNames"
            item-text="companyName"
            return-object
            v-model="selectedAgency"
          ></v-select>
        </div>
        <div v-for="account in accountsSortedByDateAndType" :key="account.id" class="px-3">
          <v-btn
            v-if="account.companyName === selectedAgency"
            :color="isSelectedAccount(account) ? 'agentPrimary' : 'grey'"
            :outlined="isSelectedAccount(account) ? false : true"
            :depressed="isSelectedAccount(account) ? true : false"
            @click="selectPersonAccount(account)"
            class="my-1"
            dark
            width="100%"
          >
            <p v-if="account.personType === 'Applicant Tenant'" class="my-0 ">Rental Account</p>
            <p v-else-if="account.personType === 'Applicant Buyer'" class="my-0 ">Buyer Account</p>
            <p v-else class="my-0 ">{{ account.personType }}</p>
          </v-btn>

        </div>
        <!-- <v-btn 
          class="mx-3 mt-6" 
          color="orange" 
          depressed
          dark
        >
          <span>Create account</span>
        </v-btn> -->
      </div>

      <div class="copyright-text">
        <p class="text-center grey--text text--lighten-2 text-caption mt-2">&#169; {{ new Date().getFullYear() }} — <strong>AgentOS</strong></p>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { API } from 'aws-amplify'


// import LandlordNavBarContent from '@/components/navBars/LandlordNavBarContent.vue';
// import TheNavDrawerContent from '@/components/navBars/TheNavDrawerContent.vue';

export default {
  name: 'theNavbar',
  components: {
    // LandlordNavBarContent
    // TheNavDrawerContent,
  },
  created() {
    if(this.selectedPersonAccount) { 
      this.selectedAgency = this.selectedPersonAccount.companyName 
    }
  },
  data() {
    return {
      drawer: null,
      selectedAgency: '',

      //Test values for mock application
      landlordUser: false,
      tenantUser: false,
      landlordNavigationTabs: [
        {name: 'Home', route: '/homepage'},
        {name: 'Viewings', route: '/viewings'},
        {name: 'Offers', route: '/offers'},
        {name: 'Agreement', route: '/signing'},
        {name: 'Hub', route: '/movein'},
        {name: 'Moving', route: '/moveout'},
        {name: 'Advertise', route: '/advertise'},
      ],
      tenantNavigationTabs: [
        {name: 'Home', route: '/tenant/Pinacle/home'},
        {name: 'Journey', route: '/tenant/Pinacle/tenant-journey'},
        {name: 'Search', route: '#'},
      ],
      buyerRentNavigationTabs: [
        {name: 'Home', route: '/homepage'},
        {name: 'Search', route: '/buyer-investment'},
        {name: 'Review', route: '/buyer-review'},
        {name: 'Offer', route: '/buyer-offer'},
        {name: 'Finances', route: '/buyer-finances'},
        {name: 'Sale', route: '/buyer-sale'},
        {name: 'Exchange', route: '/buyer-exchange'},
        {name: 'Properties', route: '#'},
      ],
      vendorNavigationTabs: [
        {name: 'Home', route: '/homepage'},
      ],
    }
  },
  methods: {
    isSelectedAccount(account) {
      if(account.personOID === this.$store.state.selectedPersonAccount.personOID) return true
      return false
    },
    selectPersonAccount(personAccount) {
      if(personAccount.personType === 'Applicant Tenant' || personAccount.personType === 'Applicant Buyer') {
        // console.log('Selected account: ', personAccount)
        this.$store.commit('RESET_PROPERTY_VALUES')
        this.$store.commit('SET_SELECTED_PERSON_ACCOUNT', personAccount)
        this.getAllAppointments()
        this.drawer = false
        if(this.$route.name !== 'Property') {
          this.$router.push({
            name: `Property`,
            params: {
              agency: `${personAccount.companyShortname}`
            }
          })
        }
      }
    },
    getAllAppointments() {
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/diary/${this.personOID}/appointments`)
        .then(res => {
          this.$store.commit('SET_SELECTED_ACCOUNT_APPOINTMENTS', res.data.Data)
          // console.log('ALL Appointments: ', res.data.Data)
        })
    }
  },
  computed: {
    ...mapState({
      personAccounts: state => state.personAccounts,
      selectedPersonAccount: state => state.selectedPersonAccount,
      personOID: state => state.selectedPersonAccount.personOID,
      clientName: state => state.selectedPersonAccount.companyShortname,
    }),
    sortedByDate() {
      if(this.$store.state.personAccounts) {
        return this.$store.state.personAccounts.slice().sort((a,b) => a.accountCreated < b.accountCreated ? 1 : -1)
      } return []
    },
    accountsSortedByCompanyName() {
      let personAccounts = this.personAccounts
      const reducedArray = personAccounts.reduce((acc, obj) => {
        const key = obj.companyName
        if(!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
      return reducedArray
    },
    accountsSortedByDateAndType() {
      const onlyApplicantTenantAndBuyer = this.personAccounts.filter( account => {
        if(account.personType === 'Applicant Tenant' || account.personType === 'Applicant Buyer') return account
      })
      return onlyApplicantTenantAndBuyer.slice().sort((a,b) => a.accountCreated < b.accountCreated ? 1 : -1) // sorted by date
    },
    agencyNames() {
      return Object.keys(this.accountsSortedByCompanyName)
    }
  },
  watch: {
    selectedPersonAccount() {
      this.selectedAgency = this.selectedPersonAccount.companyName
    }
  }
}
</script>

<style scoped>
  /* .copyright-text {
    width: 100%;
    position: fixed !important;
    bottom: 0;
  } */

  .pointer {
    cursor: pointer;
  }

</style>