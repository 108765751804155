import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    // path: '/:agency?', // question mark make param optional
    path: '/', // question mark make param optional
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/tenant/:agency', // tenant homepage 
    name: 'Tenant',
    redirect: '/tenant/:agency/home',
    component: () => import('../views/tenantViews/TenantView.vue'),
    children: [
      {
        path: '/tenant/:agency/home', // tenant homepage 
        name: 'tenantHome',
        component: () => import('../views/tenantViews/TenantHome.vue'),
      },
      {
        path: '/tenant/:agency/tenant-journey', // tenant full journey with all cards
        name: 'tenantJourney',
        component: () => import('../views/tenantViews/TheTenantJourney.vue')
      },
    ]
  },
  {
    path: '/property/:agency', // general property page for tenant and buyer app
    name: 'Property',
    component: () => import('../views/Property.vue')
  },
  {
    path: '/property-details/:agency/:id', // general property page for tenant and buyer app
    name: 'PropertyFullDetails',
    component: () => import('../views/PropertyFullDetails.vue')
  },
  {
    path: '/payments', // User account details page
    name: 'Payments',
    component: () => import('../views/Payments.vue')
  },
    {
      path: '/payments/success', // User account details page
      name: 'PaymentsSuccess',
      component: () => import('../views/PaymentsSuccess.vue')
    },
    {
      path: '/payments/error', // User account details page
      name: 'PaymentsError',
      component: () => import('../views/PaymentsError.vue')
    },
  {
    path: '/account', // User account details page
    name: 'AccountDetails',
    component: () => import('../views/AccountDetails.vue')
  },
  {
    path: '/contacts', // User contacts list page
    name: 'UserContacts',
    component: () => import('../views/UserContacts.vue')
  },

// LANDLORD APP =================================================
// ==============================================================
  // {
  //   path: '/viewings',
  //   name: 'Viewings',
  //   component: () => import('../views/Viewings.vue')
  // },
  // {
  //   path: '/offers',
  //   name: 'OffersApplications',
  //   component: () => import('../views/OffersApplications.vue')
  // },
  // {
  //   path: '/referencing',
  //   name: 'Referencing',
  //   component: () => import('../views/Referencing.vue')
  // },
  // {
  //   path: '/signing',
  //   name: 'Signing',
  //   component: () => import('../views/Signing.vue')
  // },
  {
    path: '/review',
    name: 'ReviewAgreement',
    component: () => import('../views/ReviewAgreement.vue'),
    children: [
      {
        path: 'summary',
        name: 'Summary',
        component: () => import('../views/reviewAgreementViews/Summary.vue')
      },
      {
        path: 'responsibility',
        name: 'YourResponsibility',
        component: () => import('../views/reviewAgreementViews/YourResponsibility.vue')
      },
      {
        path: 'landlord',
        name: 'LandlordResponsibility',
        component: () => import('../views/reviewAgreementViews/Landlord.vue')
      },
      {
        path: 'insurance',
        name: 'InsuranceResponsibility',
        component: () => import('../views/reviewAgreementViews/Insurance.vue')
      },
      {
        path: 'utilities',
        name: 'UtilitiesResponsibility',
        component: () => import('../views/reviewAgreementViews/Utilities.vue')
      },
      {
        path: 'deposit',
        name: 'DepositScheme',
        component: () => import('../views/reviewAgreementViews/DepositScheme.vue')
      },
      {
        path: 'deposit-info',
        name: 'DepositInfo',
        component: () => import('../views/reviewAgreementViews/DepositInfo.vue')
      },
      {
        path: 'conditions',
        name: 'SpecialConditions',
        component: () => import('../views/reviewAgreementViews/SpecialConditions.vue')
      },
      {
        path: 'sign',
        name: 'SignHere',
        component: () => import('../views/reviewAgreementViews/SignHere.vue')
      },
    ]
  },
  // {
  //   path: '/movein',
  //   name: 'MoveIn',
  //   component: () => import('../views/MovingIn.vue')
  // },
  // {
  //   path: '/rent',
  //   name: 'PayingRent',
  //   component: () => import('../views/PayingRent.vue')
  // },
  // {
  //   path: '/renewal',
  //   name: 'Renewal',
  //   component: () => import('../views/Renewal.vue')
  // },
  // {
  //   path: '/moveout',
  //   name: 'MoveOut',
  //   component: () => import('../views/MoveOut.vue')
  // },
  // {
  //   path: '/advertise',
  //   name: 'Advertise',
  //   component: () => import('../views/Advertise.vue')
  // },
  // {
  //   path: '/create-tenancy',
  //   name: 'CreateTenancy',
  //   component: () => import('../views/CreateTenancy.vue')
  // },


  // BuyerApp ---------------------------------------------------------
  //-------------------------------------------------------------------
  // {
  //   path: '/buyer-investment',
  //   name: 'BuyerInvestment',
  //   component: () => import('../views/BuyerInvestment.vue')
  // },
  // {
  //   path: '/buyer-review',
  //   name: 'BuyerReview',
  //   component: () => import('../views/BuyerReview.vue')
  // },
  // {
  //   path: '/buyer-offer',
  //   name: 'BuyerOffer',
  //   component: () => import('../views/BuyerOffer.vue')
  // },
  // {
  //   path: '/buyer-finances',
  //   name: 'BuyerFinances',
  //   component: () => import('../views/BuyerFinances.vue')
  // },
  // {
  //   path: '/buyer-sale',
  //   name: 'BuyerSale',
  //   component: () => import('../views/BuyerSale.vue')
  // },
  // {
  //   path: '/buyer-exchange',
  //   name: 'BuyerExchange',
  //   component: () => import('../views/BuyerExchange.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) { // scroll to top of page on all navigations
    if (savedPosition) {
      return savedPosition
    } else {
      return { y: 0 }
    }
  }
})


// NB: Need to save user to local storage in $store mutation!!!

// router.beforeEach((to, from, next) => {
//   const loggedIn = localStorage.getItem('user') 

//   if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
//     next('/')
//   }
//   next()
// })

export default router
