<template>
  <div class="my-3 d-flex justify-center align-center">
    <v-btn @click="toPreviousPage" :disabled="$route.path === '/homepage'" small fab  class="mx-3" color="secondary"><v-icon>mdi-chevron-left</v-icon></v-btn>
    <v-btn @click="toNextPage" :disabled="$route.path === '/moveout'" small fab class="mx-3" color="secondary"><v-icon>mdi-chevron-right</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  name: 'main-card-pagination',
  props: {
    nextPage: {
      type: String
    },
    previousPage: {
      type: String
    }
  },
  methods: {
    toNextPage() {
      this.$router.push(this.nextPage)
    },
    toPreviousPage() {
      this.$router.push(this.previousPage)
    },
  }
}
</script>

<style>

</style>