import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#59ddb7', // Medium aquamarine
        secondary: '#262637',  // Raisin black
        tertiary: '#55B6B4', // Verdigris
        tertiaryDark: '#3a99a8',
        navbarColor: '#ffffff', 
        primaryGrey: '#f5f5f5', //app background color
        // AgentOS colors
        agentPrimary: '#eb5c37',
        agentSecondary: '#343351',
        // Other -------------------------------------------------
        // nrlaGreen: '#c3d423'
      },
    },
  },
});
