/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:909f0064-7da1-43f0-8d8c-3df0786acaea",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_eiYJQBQmX",
    "aws_user_pools_web_client_id": "4v2t27ljo5pdjtn6is1p9cs35t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://rrjj6jc2tnbo7j4ecjnmmxwq3q.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "agentSignMainRest",
            "endpoint": "https://q1v1mww4b8.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
